<template>
  <div>
<!--    <el-row>
      <el-col :span="10">
        <el-carousel trigger="click" height="300px" v-if="srcList.length">
          <el-carousel-item v-for="item in srcList" :key="item.id">
            <el-image
              style="width: 100%; height: 100%"
              :src="'http://yxyx.yslts.com/' + item.img_url"
            />
          </el-carousel-item>
        </el-carousel>
      </el-col>
    </el-row> -->

    <el-row style="margin: 20px 0">
      <el-col>
        <el-button type="primary" @click="onAddBtn">
          <el-icon><Plus /></el-icon> 添加
        </el-button>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="16">
        <el-table
          :data="srcList"
          ref="tableRef"
          stripe
          border
          style="width: 100%"
          :header-cell-style="{ 'text-align': 'center', background: '#f2f2f2' }"
          :cell-style="{ 'text-align': 'center' }"
        >
        <el-table-column label="序号" type="index" width="180" />
          <el-table-column label="图片" width="180">
            <template #default="{ row, $index }">
              <el-image
                @click="previewUrl($index)"
                style="width: 80px; height: 50px"
                :preview-teleported="true"
                :src="'http://yxyxa.yslts.com' + row.img_url"
                :initial-index="imgIndex"
                fit="cover"
                :preview-src-list="srcList"
              />
            </template>
          </el-table-column>
           <el-table-column label="url" width="180" prop="url" />
           <el-table-column label="排序" width="180" prop="sort" />
          <!-- <el-table-column label="序号" type="index" width="180" /> -->
          <el-table-column prop="address" label="操作">
            <template #default="{ row }">
              <el-button type="primary" size="small" @click.prevent="edit(row)">
                编辑
              </el-button>

              <el-popconfirm
                title="是否删除该图片?"
                confirm-button-text="确认"
                cancel-button-text="取消"
                @confirm="deleteBtn(row.id)"
              >
                <template #reference>
                  <el-button type="danger" size="small"> 删除 </el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <!-- 新增 -->
    <el-dialog v-model="dialogVisible" title="添加二维码" width="500px">
      <Upload-img v-model="imageUrl"></Upload-img>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onCancel">取消</el-button>
          <el-button type="primary" @click="onOk">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { tableRef, initSortable } from './sortable/index'
import {
  rotationListAPI,
  addRotationAPI,
  listDeleteAPI,
  editMotorAPI
} from '@/api/rotation'
import { ElMessage } from 'element-plus'
import UploadImg from '../../components/UploadImg'
/** 轮播 S */
const srcList = ref([])
const search = ref({
  page: 1,
  limit: 5
})
// 轮播列表
const onRotationList = async () => {
  const { data } = await rotationListAPI(search.value)
  srcList.value = data
  console.log(srcList.value)
}
onRotationList()

/** 轮播 E */
// 编辑
const edit = (row) => {
  imageUrl.value = row.img_url
  id.value = row.id
  dialogVisible.value = true
}

// 表格照片预览
const imgIndex = ref(0)
const previewUrl = (index) => {
  imgIndex.value = index
}

const deleteBtn = async (id) => {
  await listDeleteAPI({ id })
  ElMessage.success('删除成功')
  onRotationList()
}

// 表格拖拽相关
onMounted(() => {
  initSortable()
})

/** 新增 S */
// 图片地址
const imageUrl = ref('')
const id = ref('')
const dialogVisible = ref(false)

// 添加
const onAddBtn = () => {
  dialogVisible.value = true
}
// 取消
const onCancel = () => {
  dialogVisible.value = false
}
// 确认
const onOk = async () => {
  if (!id.value) {
    await addRotationAPI({
      id: id.value,
      img_url: imageUrl.value,
      sort: 1
    })
  } else {
    await editMotorAPI({
      id: id.value,
      img_url: imageUrl.value,
      sort: 1
    })
  }

  onRotationList()
  dialogVisible.value = false
  id.value = ''
  imageUrl.value = ''
}
/** 新增 E */
</script>

<style lang="scss" scoped>
.demonstration {
  color: var(--el-text-color-secondary);
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

::v-deep .sortable-ghost {
  opacity: 0.6;
  color: #fff !important;
  background: #304156 !important;
}

.el-upload--picture-card {
  display: none !important;
}

::v-deep .disable .el-upload--picture-card {
  display: none !important;
}
</style>
